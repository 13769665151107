<template>
	<div class="tl-login-bg" style="padding-top: 120px;">


		<div class="L-container-80 ">
			<div class="L-text-align-center L-font-h3" style="color:#fff">Login</div>

			<el-row :gutter="10" class="L-margin-top-40">
				<el-input placeholder="请输入姓名" v-model="loginForm.username">
					<i slot="prefix" class="el-input__icon el-icon-user tl-login-icon"></i>
				</el-input>
			</el-row>

			<el-row :gutter="10" class="L-margin-top-20">
				<el-input placeholder="请输入手机号" v-model="loginForm.phone">
					<i slot="prefix" class="el-input__icon el-icon-mobile-phone tl-login-icon"></i>
				</el-input>

			</el-row>


			<el-row :gutter="10" class="L-margin-top-20" tyle="flex" justify="space-between" align="middle">
				<el-input placeholder="请输入验证码" v-model="loginForm.code" style="width: 160px;">
					<i slot="prefix" class="el-input__icon el-icon-key tl-login-icon"></i>
				</el-input>
				&nbsp;
				<el-button style="margin-left:20px" class="get-code-btn">获取验证码</el-button>

			</el-row>


			<div class="L-margin-top-40">
				<el-button type="primary" round style="width: 100%;" @click="submitLogin()">登 录</el-button>
			</div>

		</div>



	</div>

</template>

<script>
	export default {
		data() {
			return {
				loginForm: {
					username: '',
					phone: '',
					code: ''
				}
			};
		},
		methods: {
			toList() {
				this.$router.push({
					path: "/policestation/barList",
				})
			},
			submitLogin() {
				if (this.loginForm.username == '') {
					this.$message.error('请填写用户名');
					return;
				}
				if (this.loginForm.phone == '') {
					this.$message.error('请填写手机');
					return;
				}
				if (this.loginForm.code == '') {
					this.$message.error('请填写验证码');
					return;
				}

				// this.$axios({
				// 		method: "post",
				// 		url: "/findDjHotelList",
				// 		params: this.loginForm,
				// 		// headers: {
				// 		// 	'Content-Type': 'application/x-www-form-urlencoded',
				// 		// 	'Accept': 'application/json',
				// 		// 	'access-token': this.getAccessToken(this.logForm),
				// 		// }
				// 	})
				// 	.then(res => {
				// 		console.

				// 	});
				if (this.loginForm.username == 'levy' && this.loginForm.phone != '' && this.loginForm.code == '777777') {
					this.$message('登录成功');
					setTimeout(() => {
						this.$router.push({
							path: "/policestation/barList",
						})
					}, 3000)


				}



			},
		}
	}
</script>

<style>
	


	.tl-login-icon {
		font-size: 18px;
	}

	.tl-login-input-style {
		background: rgba(255, 255, 255, 0.1);
	}

	.tl-login-bg .el-input__inner {
		background: rgba(255, 255, 255, 0.1);
		color: #fff;
	}

	.tl-login-bg {
		width: 100%;
		height: 100%;
		margin: 0px;
		background: url("/img/img_loginbg.jpg") no-repeat;
		background-size: 100% 100%;
		background-attachment: fixed;
	}

	.get-code-btn {
		background: rgba(255, 255, 255, 0.1);
		color: #ddd;

	}
</style>
